<!--
  * @Author: 黎加冬
  * @Date: 2020-08-10
 * @LastEditTime: 2024-03-14 09:51:23
 * @LastEditors: Please set LastEditors
  * @Description: 分析建模-汇总报表
-->
<template>
  <enReportHomeTotalReport :templateId="templateId" :isPreview="isPreview" :type="type" :refConditions="refConditions"></enReportHomeTotalReport>
</template>

<script>

export default {
  name: "HomeTotalReport",
  props: {
    templateId: {
      type: String,
      default: ""
    },
    isPreview: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    refConditions: {
      type: [Array, String],
      default: () => []
    }
  }
};
</script>
