<template>
  <div class="report-wrap">
    <card-header :title="reportName" :edit="edit"  @editTheme='editTheme'  :menuData='menuData' @deleteData="deleteData" class='report-wrap-header'>
      <template #right>
        <div >
          <en-icon v-if="edit"  class="header-right-edit" name="bianji1" size="12px" @click.native="editData"></en-icon>
          <en-icon v-else  @click.native="toReport" class="header-right-arrow" name="shouqi"></en-icon>
        </div>
      </template>
    </card-header>
    <div class='equal-main'>
      <component v-if="componentName && refreshId" :is="componentName" :isNewFlag="reportInfo.isNewFlag" :template-id="menuData.refId"></component>
      <img v-else width="100%" height="100%" src="@/assets/images/big-column-col.png" alt="">
    </div>
    <en-dialog
      :visible="dialogVisible"
      class="report-list-dialog"
      width="72%"
      title="报表查询"
      append-to-body
      lock-scroll
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <div class='report-list-content'>
        <report-list ref="reportList" :isIndex="true" v-model="selectReportId" @complete="selectReportSuccess"></report-list>
      </div>
      <div slot="footer" style="height: 32px">
        <en-button  @click.native="complete">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import HomeCustomReport from "@/views/report/analysisModel/homeCustomReport";
import HomeReportDetail from "@/views/report/analysisModel/homeReportDetail";
import HomeTotalReport from "@/views/report/analysisModel/homeTotalReport";
import HomeReportAttend from "@/views/report/analysisModel/homeReportAttend";
import classifyData from "@/views/report/classifyData";
import reportList from "@/views/report/index";
import { reportService } from "@/api/report";
import CardHeader from "./card-header";

export default {
  name: "ReportComponents",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    },
    classifyList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    CardHeader,
    HomeCustomReport,
    HomeReportDetail,
    HomeTotalReport,
    HomeReportAttend,
    reportList
  },
  filters: {
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      selectReport: {},
      reportClassifyList: [...this.classifyList],
      selectReportId: "",
      // bug36906
      refreshId: true
    };
  },
  watch: {
    // menuData(newValue) {
    //   console.log(newValue, "report-components");
    // }
  },
  computed: {
    // reportName() {
    //   return this.edit ? `${this.menuData.name} ${this.reportInfo.name ? `(${ this.reportInfo.name})` : ""}` : this.reportInfo.name || this.menuData.name;
    // },
    reportName() {
        // 优先采用 headerStyle的name
        // if (this.menuData.headerStyle?.name) {
        //     return this.menuData.headerStyle.name;
        // }
        if (this.edit) {
            // console.log(this.menuData, "this.reportInfo");
            if (this.reportInfo.name) {
              return `${this.menuData.name } (${ this.reportInfo.name })`;
            }
            return this.menuData.name;
        }
        if (this.reportInfo.name) {
          return this.reportInfo.name;
        }
        return this.menuData.name;
    },
    componentName() {
      const { type, reportType } = this.reportInfo;
      // console.log("this.reportInfo1111", this.reportInfo);
      if (type === "001") {
        const nameMap = {
          "000": "HomeReportDetail",
          "001": "HomeTotalReport",
          "008": "HomeCustomReport"
        };
        return nameMap[reportType];
      }
      // 考勤报表
      if (type === "000" && reportType === "007") {
        return "HomeReportAttend";
      }
      return "";
    },
    reportInfo: {
      get() {
        return this.getReportInfo();
       },
      // setter 方法
      set(newValue) {
        console.log("computed setter...", newValue);
        // !this.reportClassifyList.length ? this.reportClassifyList.push(newValue) : "";// 编辑模式下classifyList应该是为空的
        // bug36906首页自定义切换报表后，不显示数据
        const has = this.reportClassifyList.find((v) => v.id === newValue.id);
        if (!has || !this.reportClassifyList.length) {
          this.reportClassifyList.push(newValue);
        }
      }
    }
  },
  mounted() {
    this.queryReportClassify();

    // console.log(this.menuData, "menuDatamenuDatamenuData");
    // console.log(this.classifyList, "classifyListclassifyList");
  },
  methods: {
    getReportInfo() {
      let reportInfo = {};
      this.reportClassifyList.forEach((item) => {
        item.templateList?.forEach((temp) => {
          if (temp.id === this.menuData.refId) {
            reportInfo = temp;
          }
        });
      });
      return reportInfo;
    },
    async queryReportClassify() {
      const rsp = await reportService.queryReportClassify();
      this.reportClassifyList = rsp;
      // console.log("queryReportClassify", rsp);
    },
    deleteData() {
      this.$emit("deleteData");
    },
    editTheme() {
      this.$emit("editTheme");
    },
    dialogClose() {
      this.dialogVisible = false;
      this.selectReportId = "";
      this.selectReport = {};
      this.$nextTick(() => {
        this.refreshId = true;
      });
    },
    complete() {
      this.refreshId = false;
      this.$set(this.menuData, "refId", this.selectReportId);
      this.menuData.dataType = 17;
      // this.reportInfo = cloneDeep(this.selectReport);
      // let reportInfo = {};
      // this.reportClassifyList.forEach((item) => {
      //   item.templateList?.forEach((temp) => {
      //     if (temp.id === this.menuData.refId) {
      //       reportInfo = temp;
      //     }
      //   });
      // });
      this.reportInfo = this.getReportInfo();
      this.dialogClose();
    },
    selectReportSuccess(report) {
      this.selectReport = cloneDeep(report);
    },
    editData() {
      if (!this.dialogVisible) {
        this.dialogVisible = true;
        this.selectReportId = this.menuData.refId;
        this.$nextTick(() => {
          this.$refs.reportList.updateList();
        });
      }
    },
    toReport() {
      if (Object.keys(this.reportInfo).length !== 0) {
        classifyData.jump(this.reportInfo);
      } else {
        this.$router.push("/report");
      }
    }
  }
};
</script>
<style lang="scss">
.report-wrap {
  .equal-main {
    .detail-report {
      height: 100%;
    }
    .report-detail-table {
      height: 100%;
    }
    .row-maintable {
      margin: 0;
      height: 100%;
    }
    .total-report {
      height: 100%;
      .dialog-con {
        margin-top: 0;
      }
    }
    .custom-report {
      height: 100%;
    }
    .custom-table {
      height: 100%;
    }
    .charts-map {
      height: 100%;
      padding: 10px 0 0 10px;
    }
  }
}

</style>
<style lang="scss" scoped>
.report-wrap {
  height: 100%;
  .header-right-arrow{
    width: 18px;
    height: 18px;
  }
  .equal-main {
    position: relative;
    height: calc(100% - (20px + 30px))!important;
    clear: both;
    overflow: auto;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .file-name {
    span {
      margin-left: 5px;
    }
  }
  .appendix-btn {
    cursor: pointer;
    color: #3e90fe;
  }
  /deep/ .el-tree {
    .el-tree-node__label {
      font-size: 12px;
    }
  }

}
  .report-list-dialog /deep/ .report-list-content {
    height: 55vh;
    overflow-y: scroll;
  }
  .report-list-dialog /deep/ .el-dialog__body{
    padding-right: 0;
  }
</style>
