import { reportService } from "@/api/report";
import router from "@/router";

const types = {
  "000": {
    "000": "预算执行",
    "002": "考勤汇总统计",
    "003": "外勤明细表",
    "004": "合同统计",
    "005": "流程统计",
    "006": "单据明细",
    "007": "考勤查询"
  },
  "001": {
    "000": "明细报表",
    "001": "汇总报表",
    "008": "自定义报表"
  }
};
const icons = {
  1: { "icon-name": "fenxijianmotubiao", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  2: { "icon-name": "fenxijianmotubiao6", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  3: { "icon-name": "fenxijianmotubiao1", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  4: { "icon-name": "fenxijianmotubiao7", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  5: { "icon-name": "fenxijianmotubiao4", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  6: { "icon-name": "fenxijianmotubiao3", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  7: { "icon-name": "fenxijianmotubiao5", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  8: { "icon-name": "fenxijianmotubiao2", "icon-color": "#ffffff", "icon-bg": "#3e90fe" },
  9: { "icon-name": "fenxijianmotubiao15", "icon-color": "#ffffff", "icon-bg": "#26c393" },
  10: { "icon-name": "fenxijianmotubiao18", "icon-color": "#ffffff", "icon-bg": "#26c393" },
  11: { "icon-name": "fenxijianmotubiao17", "icon-color": "#ffffff", "icon-bg": "#26c393" },
  12: { "icon-name": "fenxijianmotubiao8", "icon-color": "#ffffff", "icon-bg": "#26c393" },
  13: { "icon-name": "fenxijianmotubiao10", "icon-color": "#ffffff", "icon-bg": "#26c393" },
  14: { "icon-name": "fenxijianmotubiao9", "icon-color": "#ffffff", "icon-bg": "#26c393" },
  15: { "icon-name": "fenxijianmotubiao11", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  16: { "icon-name": "fenxijianmotubiao16", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  17: { "icon-name": "fenxijianmotubiao14", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  18: { "icon-name": "fenxijianmotubiao13", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  19: { "icon-name": "fenxijianmotubiao191", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  20: { "icon-name": "fenxijianmotubiao12", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  100: { "icon-name": "fenxijianmotubiao5", "icon-color": "#ffffff", "icon-bg": "#ffad2c" },
  101: { "icon-name": "fenxijianmotubiao5", "icon-color": "#ffffff", "icon-bg": "#ffad2c" }
};

class ClassifyData {
  reportType(type, rType) {
    return (types[type] && types[type][rType]) || "";
  }

  reportIconName(icon) {
    return icons[`${icon}`] && icons[`${icon}`]["icon-name"];
  }

  reportIconColor(icon) {
    return icons[`${icon}`] && icons[`${icon}`]["icon-color"];
  }

  reportIconBg(icon) {
    return icons[`${icon}`] && icons[`${icon}`]["icon-bg"];
  }

  async getData() {
    const rsp = await reportService.queryReportClassify();
    // console.log("queryReportClassify", rsp);
    for (let i = 0; i < rsp.length; ++i) {
      const list = rsp[i].templateList;
      for (let j = 0; j < list.length; ++j) {
        const item = list[j];
        item.iconName = this.reportIconName(item.icon);
        item.typeName = this.reportType(item.type, item.reportType);
        item.iconColor = this.reportIconColor(item.icon);
        item.iconBg = this.reportIconBg(item.icon);
      }
    }
    return rsp;
  }

  jump(reportData) {
    const {
      type, reportType, shouldGetCondition, isNewFlag, refConditions
    } = reportData;
    // const isNewFlag = reportData.isNewFlag;
    console.log("jump", type, reportType);
    const pathMap = {
      // 明细报表
      "000": "/report/analysisModel/reportDetail",
      // 汇总报表
      "001": "/report/analysisModel/totalReport",
      // 自定义报表
      "008": "/report/analysisModel/customReport",
      // 考勤报表
      "007": "/hr/reportAttend",
      // 合同统计
      "004": "/report/contract",
      // 流程统计
      "005": "/report/flow",
      "006": "/report/detail"
    };
    const templateId = reportData.id;
    if (type === "001") { // 报表
      const query = { shouldGetCondition, templateId };
      if (reportType === "008") {
        query.isNewFlag = isNewFlag;
      }
      if (pathMap[reportType]) {
        const newPage = router.resolve({
          query,
          path: pathMap[reportType]
        });
        window.open(newPage.href, "_blank");
      }
    } else { // 预算控制
      switch (reportType) {
        // 预算执行
        case "000":
          router.push({
            path: "/report/budget",
            query: { templateId }
          });
          break;
        case "002": // 考勤

          break;
        case "003": // 外勤

          break;
        case "004": // 合同统计
        case "005": // 流程统计
        case "006": // 单据明细
          router.push({
            path: pathMap[reportType],
            query: { refConditions, templateId }
          });
          break;
        case "007": // 考勤查询
          router.push({
            path: pathMap[reportType],
            query: { templateId }
          });
          break;
        default:

          break;
      }
    }
  }
}

const classifyData = new ClassifyData();

export default classifyData;
