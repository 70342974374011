<!--
  * @Author: czm
  * @Date: 2021-1-18
 * @LastEditTime: 2024-03-14 09:50:45
 * @LastEditors: Please set LastEditors
  * @Description: 分析建模-自定义报表
-->
<template>
 <enReportHomeCustomReport :templateId="templateId" :isPreview="isPreview" :type="type" :isNewFlag="isNewFlag" :refConditions="refConditions"></enReportHomeCustomReport>
</template>

<script>

export default {
  name: "HomeCustomReport",
  props: {
    templateId: {
      type: String,
      default: ""
    },
    isPreview: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    isNewFlag: {
      type: [String, Number]
    },
    refConditions: {
      type: Array,
      default: () => []
    }
  }
};
</script>
