<!--
  * @Author: czm
  * @Date: 2020-10-28
 * @LastEditTime: 2024-03-14 10:56:17
 * @LastEditors: Please set LastEditors
  * @Description: 分析建模-明细报表
-->
<template>
  <enReportHomeReportDetail
    :templateId="templateId"
    :sourceId="sourceId"
    :dataType="dataType"
    :reportType="reportType"
    :type="type"
    :refConditions="refConditions"
    :isQueryList="isQueryList"
  ></enReportHomeReportDetail>
</template>

<script>
export default {
  name: "HomeReportDetail",
  props: {
    templateId: {
      type: String,
      default: ""
    },
    sourceId: {
      type: String,
      default: ""
    },
    dataType: {
      type: String,
      default: ""
    },
    reportType: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    refConditions: {
      type: [Array, String],
      default: () => []
    },
    // 是否只需查询列表
    isQueryList: {
      type: [Boolean, String],
      default: false
    }
  }
};
</script>
