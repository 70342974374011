var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-wrap" },
    [
      _c("card-header", {
        staticClass: "report-wrap-header",
        attrs: {
          title: _vm.reportName,
          edit: _vm.edit,
          menuData: _vm.menuData,
        },
        on: { editTheme: _vm.editTheme, deleteData: _vm.deleteData },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _vm.edit
                      ? _c("en-icon", {
                          staticClass: "header-right-edit",
                          attrs: { name: "bianji1", size: "12px" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.editData.apply(null, arguments)
                            },
                          },
                        })
                      : _c("en-icon", {
                          staticClass: "header-right-arrow",
                          attrs: { name: "shouqi" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toReport.apply(null, arguments)
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "equal-main" },
        [
          _vm.componentName && _vm.refreshId
            ? _c(_vm.componentName, {
                tag: "component",
                attrs: {
                  isNewFlag: _vm.reportInfo.isNewFlag,
                  "template-id": _vm.menuData.refId,
                },
              })
            : _c("img", {
                attrs: {
                  width: "100%",
                  height: "100%",
                  src: require("@/assets/images/big-column-col.png"),
                  alt: "",
                },
              }),
        ],
        1
      ),
      _c(
        "en-dialog",
        {
          staticClass: "report-list-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            width: "72%",
            title: "报表查询",
            "append-to-body": "",
            "lock-scroll": "",
            "close-on-click-modal": false,
          },
          on: { close: _vm.dialogClose },
        },
        [
          _c(
            "div",
            { staticClass: "report-list-content" },
            [
              _c("report-list", {
                ref: "reportList",
                attrs: { isIndex: true },
                on: { complete: _vm.selectReportSuccess },
                model: {
                  value: _vm.selectReportId,
                  callback: function ($$v) {
                    _vm.selectReportId = $$v
                  },
                  expression: "selectReportId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { height: "32px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "en-button",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.complete.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }