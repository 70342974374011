<!--
 * @Author: zhangjiaheng
 * @Date: 2021-01-13 10:23:34
 * @LastEditTime: 2024-03-14 09:50:27
 * @LastEditors: Please set LastEditors
 * @Description: 固定报表 考勤查询 路由地址 /hr/reportAttend
 * @FilePath: \user\src\views\hr\report\index.vue
-->
<template>
 <enReportHomeReportAttend :templateId="templateId"></enReportHomeReportAttend>
</template>

<script>

export default {
  name: "HomeReportAttend",
  props: {
    templateId: {
      type: String,
      default: ""
    }
  }
};
</script>
