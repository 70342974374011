var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("enReportHomeReportDetail", {
    attrs: {
      templateId: _vm.templateId,
      sourceId: _vm.sourceId,
      dataType: _vm.dataType,
      reportType: _vm.reportType,
      type: _vm.type,
      refConditions: _vm.refConditions,
      isQueryList: _vm.isQueryList,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }